.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

h1,
p {
  padding: 0px;
  margin: 0px;
}

.App-link {
  color: #61dafb;
}

.widen {
  letter-spacing: 11px;
}

sub {
  font-size: 0.4em;
  padding-bottom: 20px;
}